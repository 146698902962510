import React from "react";
import Layout from "../components/layout";
import Header from "../components/header";
import SEO from "../components/seo";
import MarketingImage from "../components/marketing_image";
import ContactUs from "../components/contact_us";
import OurClients from "../components/our_clients";

import "../styles/index.css";

const Headline = () => (
  <div className="flex-1 flex flex-col-reverse md:flex-row justify-center items-center">
    <div className="w-full text-center md:w-1/2 px-6 md:px-12 py-6 md:py-0">
      <h1 className="font-bold text-4xl md:text-6xl mb-8">DeepTech</h1>
      <h2 className="text-2xl md:text-3xl">IT Consulting</h2>
      <p className="text-lg md:text-xl pt-2">
        Information Technology consulting company specializing in Digital
        Transformation, AI and Machine Learning, Cybersecurity, Software
        Engineering, and Business Intellegince.
      </p>
    </div>
  </div>
);

export default () => (
  <Layout isIndexPage={true}>
    <SEO title="Home" />
    <div className="font-serif flex flex-col">
      <div className="min-h-screen flex flex-col">
        <Header />
        <Headline />
      </div>
      <OurClients />
      <ContactUs />
    </div>
  </Layout>
);
