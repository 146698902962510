import React from "react";

export default () => {
  return (
    <div className="flex flex-col justify-center items-center py-8 text-black">
      <h3 className="font-serif font-bold text-3xl py-4 mb-8">Contact Us</h3>
      <div className="flex flex-col items-start md:flex-row md:justify-center mb-8 text-2xl">
        contact@deeptech.sa
      </div>
    </div>
  );
};
