import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

const query = graphql`
  query {
    eighties: file(relativePath: { eq: "eighties-logo.png" }) {
      childImageSharp {
        fixed(height: 70) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    acast: file(relativePath: { eq: "acast-logo.png" }) {
      childImageSharp {
        fixed(height: 85) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    aktal: file(relativePath: { eq: "aktal-logo.png" }) {
      childImageSharp {
        fixed(height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    daralqias: file(relativePath: { eq: "daralqias-logo.png" }) {
      childImageSharp {
        fixed(height: 80) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;

export default () => {
  const data = useStaticQuery(query);
  return (
    <div className="flex flex-col justify-center items-center py-8 bg-gray-200 text-black">
      <h3 className="font-serif font-bold text-3xl py-4">Our Clients</h3>
      <div className="flex flex-col md:flex-row justify-center items-center pt-8">
        <Img fixed={data.eighties.childImageSharp.fixed} className="m-8" />
        <Img fixed={data.acast.childImageSharp.fixed} className="m-8" />
        <Img fixed={data.aktal.childImageSharp.fixed} className="m-8" />
        <Img fixed={data.daralqias.childImageSharp.fixed} className="m-8" />
      </div>
    </div>
  );
};
